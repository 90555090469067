const getStyles = (theme) => ({
  card: {
    height: '24rem'
  },
  fontCard: {
    fontSize: "13px"
  },
  addButton: {
    height: "41px"
  }
});

export default getStyles;
