import React from 'react';
import PropTypes from 'prop-types';
import getStyles from "./styles";
import placeHolder from "../../../assets/placeholder.jpg"
import { useStyles } from "react-styles-hook";
import { useCart } from "react-use-cart";
import { Link } from 'gatsby';
import { useDispatch } from 'react-redux'
import { launchedSnackBar } from "../../templates/PageList/reducer";
import { sellerLaunchedSnackBar } from '../../templates/SellerPage/reducer';


const ProductListCard = ({ product, productBanner }) => {
  const { addItem } = useCart();
  const classes = useStyles(getStyles());
  const dispatch = useDispatch();

  const handleClick = (product) => {
    dispatch(launchedSnackBar())
    dispatch(sellerLaunchedSnackBar())
    addItem(product)
  }
  let isBanner = false;
  if (productBanner) {
    isBanner = true;
  }

  return (
    <div
      className="hover:transform hover:translate-y-4 duration-300"
    >
      <div className={"flex flex-col items-center justify-between"} style={isBanner ? '' : classes.card}>
        {/* image */}
        <Link
          to={product.urlProductPage}
          className={
            "flex flex-col items-center"
          }
        >
          {product.image ?
            <img
              className="object-scale-down h-36 md:h-44 w-full"
              alt={product.title}
              src={product.image.file.url}
            />
            :
            <img
              className="object-scale-down h-36 md:h-44 w-full"
              src={placeHolder}
              alt={product.title}
            />
          }
          {product.flapSelectionZawema ? (
            <span
              style={classes.fontCard}
              className="self-center bg-orange-dark text-white mt-1 px-4"
            >
              Sélection Zawema
            </span>
          ) : (
            <span className="self-center mt-1 px-4 h-5"></span>
          )}
        </Link>
        {/* product info */}
        <div className="w-full text-center">
          <Link to={product.urlProductPage} className="text-center font-semibold line-clamp-2">{product.title}</Link>
          <h3 className="text-center font-normal line-clamp-2">
            Vendu par {product.corporateName}
          </h3>
          <div className={"flex flex-row justify-evenly w-full"}>
            <span className={"text-center font-semibold text-orange-darkest"}>
              {((product.price * 100) / 100).toFixed(2)} €
            </span>
            {(product.crossedPrice !== "null" && product.crossedPrice !== null && product.crossedPrice !== "0" && product.crossedPrice !== 0) && (// Contentful return "null", Algolia return null
              <span className={"h-5 text-center line-through text-orange-crossed"}>
                {((product.crossedPrice * 100) / 100).toFixed(2)} €
              </span>
            )}
            {(product.priceDescription !== "null" && product.priceDescription !== null && product.priceDescription !== undefined) && (
              <div className="h-6 flex flex-row justify-center mb-2 text-xs text-grey-dark font-normal italic">
                <span>{product.priceDescription}</span>
              </div>
            )}
          </div>
        </div>
        {/* add to cart */}
        <button
          type="button"
          style={classes.addButton}
          className="w-full md:w-3/4 lg:w-2/3 2xl:w-1/2 border-solid border-2 border-grey-medium border-opacity-100 focus:outline-none hover:bg-orange-dark hover:text-white hover:border-orange-dark hover:text-bold"
          onClick={() => handleClick(product)}
        >
          Ajouter
        </button>
      </div>

    </div>
  );
}

export default ProductListCard;

ProductListCard.propTypes = {
  product: PropTypes.object.isRequired,
};